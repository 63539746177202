import { motion } from 'framer-motion'
import { useState, useEffect } from 'react'
import { TbPointFilled } from 'react-icons/tb'
import { useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'

import DropdownArrow from '@/assets/icons/dropdown-arrow.svg'
import Input from '@/components/Input/Input'
import { filterText } from '@/utils'

import './actionMenuList.scss'

const ActionMenuList = ({ handleClickActionItem: _handleClickActionItem = () => {} }) => {
  const [searchText, setSearchText] = useState('')
  const [visibleGroupId, setVisibleGroupId] = useState(null)

  useEffect(() => {
    setTimeout(() => {
      const inputElement = document.querySelector('.action-menu-list__search-input');
      if (inputElement) {
        inputElement.value = "";
      }
    }, 100);
  }, []);

  const location = useLocation()
  const projectApis = useSelector(state => state.app.projectApis)
  const hasApis = projectApis.length !== 0

  const filteredApis = projectApis
    .map(apiGroup => ({
      ...apiGroup,
      api: apiGroup.api.filter(apiItem => filterText(searchText, apiItem.title)),
    }))
    .filter(apiGroup => apiGroup.api.length > 0)
  const isActiveFilterStatus = !!searchText

  const checkActiveItem = path => location.pathname === path

  const handleClearVisibleGroupId = () => {
    setVisibleGroupId(null)
  }

  const handleClickGroupItem = groupId => () => {
    if (groupId === visibleGroupId) {
      handleClearVisibleGroupId()
      return
    }

    setVisibleGroupId(groupId)
  }

  const checkVisibleGroup = groupId => {
    return isActiveFilterStatus || groupId === visibleGroupId
  }

  const handleSearchInput = e => setSearchText(e.target.value)

  const handleClickActionItem = item => () => {
    _handleClickActionItem(item)
    setSearchText('')
  }

  const renderApiTitle = title => {
    const titleParts = title.split(new RegExp(`(${searchText})`, 'gi'))

    return titleParts.map((titlePart, i) => (
      <span key={i} {...(titlePart.toLowerCase() === searchText.toLowerCase() ? { className: 'action-menu-list__highlight-text' } : {})}>
        {titlePart}
      </span>
    ))
  }

  return (
    <div className='action-menu-list__list'>
      {!hasApis ? (
        <div className='action-menu-list__no-actions'>Hiç eklenmiş aksiyon yok</div>
      ) : (
        <>
          <Input
            autoComplete="off"
            readOnly={true}
            onFocus={e => e.target.removeAttribute('readonly')}
            name={`action_menu_list_filter_${Math.random()}`}
            id={`action_menu_list_filter_${Math.random()}`}
            onChange={handleSearchInput}
            value={searchText}
            placeholder="Aksiyon ara..."
            className="action-menu-list__search-input"
          />
          {filteredApis.length === 0 ? (
            <div className='action-menu-list__no-filtered-actions'>Hiç aksiyon yok</div>
          ) : (
            filteredApis.map(apiGroup => (
              <div key={apiGroup.id} className='action-menu-list__item-wrapper'>
                <div onClick={handleClickGroupItem(apiGroup.id)} className='action-menu-list__item'>
                  <img
                    src={DropdownArrow}
                    className={`action-menu-list__icon ${checkVisibleGroup(apiGroup.id) ? 'action-menu-list__icon--selected' : ''}`}
                  />
                  <div className='action-menu-list__text'>{apiGroup.name}</div>
                </div>
                <motion.div
                  initial={false}
                  animate={checkVisibleGroup(apiGroup.id) ? 'open' : 'closed'}
                  transition={{ ease: 'linear', duration: 0.08 }}
                  variants={{
                    open: { opacity: 1, height: '100%' },
                    closed: { opacity: 0, height: 1 },
                  }}
                  className='action-menu-list__nested-list'>
                  {apiGroup.api.map(apiItem => (
                    <Link
                      onClick={handleClickActionItem(apiItem)}
                      to={`/api-execute/${apiItem.hashId}`}
                      key={`${apiGroup.id}_${apiItem.hashId}`}
                      className={`action-menu-list__nested-item ${checkActiveItem(`/api-execute/${apiItem.hashId}`) ? 'action-menu-list__nested-item--selected' : ''}`}>
                      <TbPointFilled className='action-menu-list__nested-item-icon' />
                      <span>{renderApiTitle(apiItem.title)}</span>
                    </Link>
                  ))}
                </motion.div>
              </div>
            ))
          )}
        </>
      )}
    </div>
  )
}

export default ActionMenuList
